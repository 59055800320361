import React from "react";

import '../assets/styles/Footer.scss'

function Footer() {
  return (
    <footer>
      
      
    </footer>
  );
}

export default Footer;