import React from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../assets/styles/Main.scss';
import avatar from '../assets/images/avatar.png';

function Main() {

  return (
    <div className="container">
      <div className="about-section">
        <div className="image-wrapper">
        <img src={avatar} alt="Avatar" />
        </div>
        <div className="content">
          
          <h1>Justin Khant</h1>
          <p>iOS Engineer</p>

          
        </div>
      </div>
    </div>
  );
}

export default Main;