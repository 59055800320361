import React from "react";
import '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faAppStoreIos } from '@fortawesome/free-brands-svg-icons';
import { faListCheck, faCertificate } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Chip from '@mui/material/Chip';
import Cert01 from '../assets/images/cert01.png';
import Cert02 from '../assets/images/cert02.png';
import '../assets/styles/Expertise.scss';

const labelsFirst = [
    "React",
    "TypeScript",
    "JavaScript",
    "HTML5",
    "CSS3",
    "Flask",
    "Python",
];

const labelsSecond = [
    "SwiftUI",
    "Git",
    "GitHub Actions",
    "Firebase",
];

const labelsThird = [
    "Jira",
    "Certified Associate in Project Management",
    "Google Project Management Certificate"
];

function Expertise() {
    return (
        <div className="container" id="expertise">
            <div className="skills-container">
                <h1>Expertise</h1>
                <div className="skills-grid">
                    <div className="skill">
                        <FontAwesomeIcon icon={faReact} size="3x"/>
                        <h3>Full Stack Web Development</h3>
                        <p>I have built a diverse array of web applications from scratch using modern technologies such as React and Flask. I have a strong proficiency in the SDLC process and frontend + backend development.</p>
                        <div className="flex-chips">
                            <span className="chip-title">Tech stack:</span>
                            {labelsFirst.map((label, index) => (
                                <Chip key={index} className='chip' label={label} />
                            ))}
                        </div>
                    </div>

                    <div className="skill">
                        <FontAwesomeIcon icon={faAppStoreIos} size="3x"/>
                        <h3>iOS Development</h3>
                        <p>Once the application is built, I help clients set up DevOps testing, CI/CD pipelines, and deployment automation to support the successful Go-Live.</p>
                        <div className="flex-chips">
                            <span className="chip-title">Tech stack:</span>
                            {labelsSecond.map((label, index) => (
                                <Chip key={index} className='chip' label={label} />
                            ))}
                        </div>
                    </div>

                    <div className="skill">
                        <FontAwesomeIcon icon={faListCheck as IconProp} size="3x"/>
                        <h3>Project Management</h3>
                        <p>Stay relevant in the market by leveraging the latest AI models in your projects. I have professional experience building enterprise grade GenAI-enabled solutions to empower intelligent decision making.</p>
                        <div className="flex-chips">
                            <span className="chip-title">Tech stack:</span>
                            {labelsThird.map((label, index) => (
                                <Chip key={index} className='chip' label={label} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Certificates Section */}
            <div className="skills-container certificates-container">
                <h1>Certificates</h1>
                <div className="skills-grid certificates-grid">
                    <div className="skill">
                        <img src={Cert01} alt="CAPM Certificate" className="certificate-logo" />
                        <h3>Certified Associate in Project Management</h3>
                        <p>PMI’s CAPM® demonstrates your understanding of the fundamental knowledge, terminology, and processes of effective project management.</p>
                        <div className="flex-chips">
                            <span className="chip-title">Issuer:</span>
                            <Chip className='chip' label="PMI" />
                        </div>
                    </div>
                    <div className="skill">
                        <img src={Cert02} alt="Google Project Management Certificate" className="certificate-logo" />
                        <h3>Google Project Management Certificate</h3>
                        <p>This certificate covers the foundations of project management, including effective communication, stakeholder management, and agile methodologies.</p>
                        <div className="flex-chips">
                            <span className="chip-title">Issuer:</span>
                            <Chip className='chip' label="Google" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Expertise;
